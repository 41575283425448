var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-item-group',[_c('v-container',[_c('v-row',[_vm._l((_vm.displayedItems),function(card,j){return _c('v-col',{key:j,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-skeleton-loader',{attrs:{"width":"100%","type":"card","loading":_vm.loading}},[_c('v-card',{staticClass:"\n                  d-flex\n                  align-text-center\n                  flex-column\n                  rounded\n                  elevation-0\n                ",staticStyle:{"width":"auto","height":"200px","background":"#ffffff","border":"1px solid rgba(49, 27, 146, 0.2)","box-sizing":"border-box","border-radius":"8px"},attrs:{"width":"100%","elevation":active ? 4 : 0,"tile":""},on:{"click":toggle}},[_c('v-card-title',{staticClass:"mx-2 mt-2 text-truncate",staticStyle:{"max-width":"90%","font-family":"Inter","font-style":"normal","font-weight":"600","font-size":"14px","line-height":"24px","color":"#596a73"}},[_vm._v(_vm._s(card.form_title))]),_c('v-card-subtitle',{staticClass:"\n                    mx-2\n                    pb-2\n                    indigo--text\n                    darken-4\n                    d-flex\n                    align-center\n                    justify-space-between\n                  ",staticStyle:{"cursor":"pointer"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"text-truncate mb-0",staticStyle:{"max-width":"85%"},on:{"click":function($event){return _vm.copy(j)}}},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formBaseUrl)+_vm._s(card.hypn_id)+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.formBaseUrl)+_vm._s(card.hypn_id))])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copy(j)}}},[_c('v-icon',{staticClass:"px-2",attrs:{"x-small":""}},[_vm._v("mdi-content-copy")])],1)],1),_c('div',{staticClass:"d-flex align-center justify-space-between mt-2 mb-4"},[_c('div',{staticClass:"d-flex mx-5"},[_c('div',{},[_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":"","dark":"","color":"#636b70"},on:{"click":function($event){return _vm.showForm(1, j)}}},[_vm._v(_vm._s(card.entries)+" ")]),_c('h5',{staticClass:"text--disabled text-caption pl-1"},[_vm._v(" entries ")])],1),(card.is_payment)?_c('v-chip',{staticClass:"mr-1 font-weight-bold font-italic text--gray",attrs:{"label":"","small":"","color":"#D5F7EF"}},[_vm._v("P")]):_vm._e(),(card.has_signature)?_c('v-chip',{staticClass:"mr-1 font-weight-bold font-italic",attrs:{"label":"","small":"","color":"#EEF3FB"}},[_vm._v("S")]):_vm._e()],1),_c('div',[_c('v-switch',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"true","dense":"","value":card.hypn_id,"multiple":"","color":"#1a1544"},model:{value:(_vm.inActiveForms),callback:function ($$v) {_vm.inActiveForms=$$v},expression:"inActiveForms"}})],1)]),_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"30px","width":"100%","height":"48px","position":"absolute","bottom":"0","border-top":"1px solid rgba(49, 27, 146, 0.2)","box-sizing":"border-box"}},[_c('p',{staticClass:"ml-4 primary--text",staticStyle:{"font-family":"Inter","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"18px","letter-spacing":"0.45px","mix-blend-mode":"normal","opacity":"0.5"},style:({
                      marginTop: ("" + (_vm.$vuetify.breakpoint.md ? '0.7em' : '1.2em')),
                    })},[_vm._v(" Created "+_vm._s(_vm._f("date")(card.created_at))+" ")]),_c('v-spacer'),_vm._l((_vm.icons),function(icon,index){return _c('v-btn',{key:icon.title,staticClass:"px-0 mx-0",staticStyle:{"margin-top":"4px"},attrs:{"icon":"","id":"no-background-hover"},on:{"click":function($event){return _vm.showForm(index, j)}},model:{value:(_vm.selectedIcon),callback:function ($$v) {_vm.selectedIcon=$$v},expression:"selectedIcon"}},[_c('v-icon',{staticClass:"pl-1",class:{ last: index === _vm.icons.length - 1 },attrs:{"color":"#7F919B","small":"","to":"icon.path"}},[_vm._v(" "+_vm._s(icon.title)+" ")])],1)})],2)],1)],1)]}}],null,true)})],1)}),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"border-color":"rgba(49, 27, 146, 0.2)"},attrs:{"color":"#f8f8f8","max-width":"100%","height":"200","outlined":""}},[_c('v-card-actions',{staticClass:"ma-0 pa-0"},[_c('v-btn',{attrs:{"plain":"","fab":""},on:{"click":_vm.activateModal}},[_c('simple-line-icons',{staticStyle:{"font-family":"simple-line-icons","font-style":"normal","font-weight":"normal","font-size":"45px","line-height":"16px","mix-blend-mode":"normal","opacity":"0.5"},attrs:{"icon":"plus","color":"#7f919b","no-svg":""}})],1)],1),_c('v-card-title',{staticClass:"ma-0 px-0"},[_c('p',[_vm._v("New Form")])])],1)],1)],2),(_vm.formCards && _vm.formCards.length > 0)?_c('div',{staticClass:"pagination__container"},[_c('BasePagination',{attrs:{"current-page":_vm.currentPage,"visible-pages":_vm.visiblePageValue,"total-pages":_vm.totalPages},on:{"page-changed":_vm.handlePageChange}})],1):_vm._e()],1)],1),_c('DeleteForm',{ref:"deleteForm",on:{"form-deleted":_vm.formRecall}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }