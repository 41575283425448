<template>
  <div>
    <v-item-group>
      <v-container>
        <v-row>
          <v-col
            v-for="(card, j) in displayedItems"
            :key="j"
            cols="12"
            sm="6"
            md="4"
          >
            <v-item v-slot:default="{ active, toggle }">
              <v-skeleton-loader width="100%" type="card" :loading="loading">
                <v-card
                  width="100%"
                  @click="toggle"
                  :elevation="active ? 4 : 0"
                  class="
                    d-flex
                    align-text-center
                    flex-column
                    rounded
                    elevation-0
                  "
                  tile
                  style="
                    width: auto;
                    height: 200px;
                    background: #ffffff;
                    border: 1px solid rgba(49, 27, 146, 0.2);
                    box-sizing: border-box;
                    border-radius: 8px;
                  "
                >
                  <v-card-title
                    class="mx-2 mt-2 text-truncate"
                    style="
                      max-width: 90%;
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 24px;
                      color: #596a73;
                    "
                    >{{ card.form_title }}</v-card-title
                  >
                  <v-card-subtitle
                    class="
                      mx-2
                      pb-2
                      indigo--text
                      darken-4
                      d-flex
                      align-center
                      justify-space-between
                    "
                    style="cursor: pointer"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          @click="copy(j)"
                          class="text-truncate mb-0"
                          style="max-width: 85%"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ formBaseUrl }}{{ card.hypn_id }}
                        </p>
                      </template>
                      <span style="font-size: 12px"
                        >{{ formBaseUrl }}{{ card.hypn_id }}</span
                      >
                    </v-tooltip>

                    <v-btn @click="copy(j)" icon>
                      <v-icon x-small class="px-2">mdi-content-copy</v-icon>
                    </v-btn>
                  </v-card-subtitle>

                  <div
                    class="d-flex align-center justify-space-between mt-2 mb-4"
                  >
                    <div class="d-flex mx-5">
                      <div class="">
                        <v-chip
                          label
                          small
                          dark
                          color="#636b70"
                          class="mx-1"
                          @click="showForm(1, j)"
                          >{{ card.entries }}
                        </v-chip>
                        <h5 class="text--disabled text-caption pl-1">
                          entries
                        </h5>
                      </div>
                      <v-chip
                        v-if="card.is_payment"
                        label
                        small
                        color="#D5F7EF"
                        class="mr-1 font-weight-bold font-italic text--gray"
                        >P</v-chip
                      >
                      <v-chip
                        v-if="card.has_signature"
                        label
                        small
                        color="#EEF3FB"
                        class="mr-1 font-weight-bold font-italic"
                        >S</v-chip
                      >
                    </div>
                    <div>
                      <v-switch
                        class="pt-0 mt-0"
                        hide-details="true"
                        dense
                        :value="card.hypn_id"
                        v-model="inActiveForms"
                        multiple
                        color="#1a1544"
                      ></v-switch>
                    </div>
                  </div>
                  <div
                    class="d-flex"
                    style="
                      margin-top: 30px;
                      width: 100%;
                      height: 48px;
                      position: absolute;
                      bottom: 0;
                      border-top: 1px solid rgba(49, 27, 146, 0.2);
                      box-sizing: border-box;
                    "
                  >
                    <p
                      class="ml-4 primary--text"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.45px;
                        mix-blend-mode: normal;
                        opacity: 0.5;
                      "
                      :style="{
                        marginTop: `${
                          $vuetify.breakpoint.md ? '0.7em' : '1.2em'
                        }`,
                      }"
                    >
                      Created {{ card.created_at | date }}
                    </p>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-model="selectedIcon"
                      @click="showForm(index, j)"
                      class="px-0 mx-0"
                      style="margin-top: 4px"
                      icon
                      id="no-background-hover"
                      v-for="(icon, index) in icons"
                      :key="icon.title"
                    >
                      <v-icon
                        v-bind:class="{ last: index === icons.length - 1 }"
                        color="#7F919B"
                        small
                        class="pl-1"
                        to="icon.path"
                      >
                        {{ icon.title }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-skeleton-loader>
            </v-item>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="#f8f8f8"
              max-width="100%"
              height="200"
              outlined
              style="border-color: rgba(49, 27, 146, 0.2)"
            >
              <v-card-actions class="ma-0 pa-0">
                <v-btn plain fab @click="activateModal">
                  <simple-line-icons
                    icon="plus"
                    color="#7f919b"
                    style="
                      font-family: simple-line-icons;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 45px;
                      line-height: 16px;
                      mix-blend-mode: normal;
                      opacity: 0.5;
                    "
                    no-svg
                  />
                </v-btn>
              </v-card-actions>
              <v-card-title class="ma-0 px-0">
                <p>New Form</p>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <div
          class="pagination__container"
          v-if="formCards && formCards.length > 0"
        >
          <BasePagination
            :current-page="currentPage"
            :visible-pages="visiblePageValue"
            :total-pages="totalPages"
            @page-changed="handlePageChange"
          />
        </div>
      </v-container>
    </v-item-group>
    <DeleteForm ref="deleteForm" @form-deleted="formRecall" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteForm from "../includes/overlays/deleteForm.vue";
import pagination from "@/mixins/pagination";

import BasePagination from "@/components/BasePagination.vue";

export default {
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  mixins: [pagination],
  data() {
    return {
      inActiveForms: [],
      selectedIcon: null,
      formEntries: [],
      formBaseUrl:
        process.env.NODE_ENV === "production"
          ? "https://hypn.so/"
          : "https://dev.hypn.so/",
      icons: [
        { title: "mdi-pencil-outline", path: "#" },
        { title: "mdi-format-indent-increase", path: "#" },
        { title: "mdi-delete", path: "#" },
      ],

      loading: true,
      submitUrl: "",
      itemsPerPage: 8,
    };
  },
  components: {
    DeleteForm,
    //BasePagination,
    BasePagination,
  },
  methods: {
    ...mapActions("formBuilder", ["deleteForm"]),
    ...mapActions({ showToast: "ui/showToast" }),
    //method to emit event to open modal

    activateModal() {
      this.$parent.$emit("open-modal");
    },

    //copy to clipboard function using the v-clipboard plugin from npm
    copy(j) {
      const value = this.formBaseUrl + this.displayedItems[j].hypn_id;
      this.$clipboard(value).then(
        this.showToast({
          sclass: "success",
          show: true,
          message: `copied ${value} to clipboard`,
          timeout: 3000,
        })
      );
    },
    async showForm(index, parentIndex) {
      if (process.env.NODE_ENV === "development") {
        console.log("index", index);
        console.log("parentIndex", parentIndex);
      }

      if (index === 0) {
        if (process.env.NODE_ENV === "development") {
          console.log(
            JSON.stringify(this.displayedItems[parentIndex].entries, null, 2)
          );
        }
        this.$emit("edit-form");

        this.$router.push({
          path: `/edit-form/${this.displayedItems[parentIndex].id}`,
        });
      } else if (index === 1) {
        this.$emit("entries");

        this.$router.push({
          path: `/form/${this.displayedItems[parentIndex].id}`,
        });
      } else if (index === 2) {
        this.$refs.deleteForm.openDialog(parentIndex);
      }
    },

    newForm() {
      this.$emit("formBuilder/create-form", this.formCards);
    },

    //fetch all forms
    async fetchAllForms() {
      try {
        this.loading = true;
        const { data } = await this.$store.dispatch(
          "formBuilder/FetchAllForms"
        );
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          console.log(err);
        }
      } finally {
        this.loading = false;
      }
    },

    // re-call alForms and formEntrieCount
    formRecall() {
      this.$store.dispatch("formBuilder/getFormEntriesCount");
      this.fetchAllForms();
    },
  },
  mounted() {
    this.fetchAllForms();
    if (process.env.NODE_ENV === "development") {
      console.log(this.paginationObject);
    }
  },
  computed: {
    ...mapGetters({
      formCards: "formBuilder/forms",
    }),

    // search filter for forms
    filteredForms() {
      if (this.search) {
        return this.formCards.filter((form) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => form.form_title.toLowerCase().includes(v));
        });
      } else {
        return this.formCards;
      }
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredForms.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (this.filteredForms && this.filteredForms.length > this.itemsPerPage) {
        return this.filteredForms.slice(startIndex, endIndex);
      } else {
        return this.filteredForms.slice();
      }
    },
    totalPages() {
      return Math.ceil(this.filteredForms.length / this.itemsPerPage);
    },

    getFormById() {
      return this.forms.find((form) => form.id == this.$route.params.id).id;
    },

    //total entries suppose to come from the form input endpoint
    totalEntriesNum() {
      return this.formEntries.length;
    },
  },
};
</script>

<style>
.v-input--selection-controls {
  /* margin-top: 16
px
; */
  padding-top: 4px !important;
}

.v-card--link {
  /* cursor: pointer; */
  cursor: default !important;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  /* margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 30px;
  padding-right: -20px; */
}
</style>
